import React from "react";
import Widia from "sections/Widia/Widia";
import {useEngWidiaData} from "../../lang/en/widia";

const WidiaPage = () => {
  const data = useEngWidiaData();
  return <Widia data={data} />;
};

export default WidiaPage;
