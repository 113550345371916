import {useWidiaPhotos} from "shared/Hooks/widia/useWidiaPhotos";

export const useEngWidiaData = () => {
  const photos = useWidiaPhotos();
  return {
    metaInfo: {
      title: "Widia's full range | WUMAX - metalworking tools",
      ogDescription:
        "We offer innovative Widia metalworking tools, ensuring precise and safe work. See the catalogues and learn what Widia offers.",
      ogImage: photos.logo.childImageSharp.fluid.src,
    },
    hero: {
      title: "Widia - Fast and precise metalworking",
      subtitle:
        "Widia is a brand that offers its customers the best solutions in the field of metal processing. It is a manufacturer that offers extremely high-quality tools - enabling effective, safe and economical work. Widia tools are your guarantee of success.",
      bg: photos.hero,
    },
    mainCatalogue: {
      title: "Master Catalogue",
      subtitle:
        "The latest and complete range of the Widia products. The catalogue also contains detailed information and technical data that allow you to get acquainted with the guidelines useful for the use of devices and a thorough analysis of the included solutions. ",
      links: [
        {
          text: "View catalog",
          url: "https://s7d2.scene7.com/s7viewers/html5/eCatalogSearchViewer.html?emailurl=https://s7d2.scene7.com/s7/emailFriend&searchserverurl=http://s7search1.scene7.com/s7search/&serverUrl=https://s7d2.scene7.com/is/image/&config=Scene7SharedAssets/Universal%5FHTML5%5FeCatalog%5FSearch&contenturl=https://s7d2.scene7.com/skins/&config2=companypreset&asset=Kennametal/A%2D15%2D04580%5FMaster17%5FCatalog%5FMetric%5FLR",
        },
      ],
      bigImg: true,
      src: photos.img1,
    },
    toorning: {
      title: "Turning",
      subtitle:
        "Devices ensuring shorter metal processing time and increased work efficiency. The technologies used guarantee high resistance to thermal factors, which helps to maintain the ideal parameters of the processed elements.",
      links: [
        {
          text: "View catalog",
          url: "https://s7d2.scene7.com/s7viewers/html5/eCatalogSearchViewer.html?emailurl=https://s7d2.scene7.com/s7/emailFriend&searchserverurl=http://s7search1.scene7.com/s7search/&serverUrl=https://s7d2.scene7.com/is/image/&config=Scene7SharedAssets/Universal%5FHTML5%5FeCatalog%5FSearch&contenturl=https://s7d2.scene7.com/skins/&config2=companypreset&asset=Kennametal/A%2D19%2D06161%5FFinal%5FTurning%5FBrochure%5FSpreads",
        },
      ],
      bigImg: true,
      src: photos.img2,
    },
    innovations: {
      title: "Advances Catalogue ",
      subtitle:
        "Catalogues presenting innovative metalworking solutions with Widia tools. The devices enable efficient work and full precision of operation while maintaining environmentally friendly standards.",
      links: [
        {
          text: "Innovations 2018",
          url: "https://s7d2.scene7.com/s7viewers/html5/eCatalogSearchViewer.html?emailurl=https://s7d2.scene7.com/s7/emailFriend&searchserverurl=http://s7search1.scene7.com/s7search/&serverUrl=https://s7d2.scene7.com/is/image/&config=Scene7SharedAssets/Universal%5FHTML5%5FeCatalog%5FSearch&contenturl=https://s7d2.scene7.com/skins/&config2=companypreset&asset=Kennametal/A%2D17%2D05451%5FWID%5FAdvances18%5FMetric%5FLR",
        },
        {
          text: "Innovations 2019",
          url: "https://s7d2.scene7.com/s7viewers/html5/eCatalogSearchViewer.html?emailurl=https://s7d2.scene7.com/s7/emailFriend&searchserverurl=http://s7search1.scene7.com/s7search/&serverUrl=https://s7d2.scene7.com/is/image/&config=Scene7SharedAssets/Universal%5FHTML5%5FeCatalog%5FSearch&contenturl=https://s7d2.scene7.com/skins/&config2=companypreset&asset=Kennametal/A%2D18%2D05888%5FWID%5FAdvances19%5FMetric%5FLR%5Fr2",
        },
        {
          text: "Innovations 2020",
          url: "https://s7d2.scene7.com/s7viewers/html5/eCatalogSearchViewer.html?emailurl=https://s7d2.scene7.com/s7/emailFriend&searchserverurl=http://s7search1.scene7.com/s7search/&serverUrl=https://s7d2.scene7.com/is/image/&config=Scene7SharedAssets/Universal%5FHTML5%5FeCatalog%5FSearch&contenturl=https://s7d2.scene7.com/skins/&config2=companypreset&asset=Kennametal/A%2D19%2D06147%5FWID%5FAdvances20%5FMetric%5FLR%5FiPad",
        },
        {
          text: "Innovations 2021",
          url: "https://s7d2.scene7.com/s7viewers/html5/eCatalogViewer.html?emailurl=https://s7d2.scene7.com/s7/emailFriend&serverUrl=https://s7d2.scene7.com/is/image/&config=Scene7SharedAssets/Universal%5FHTML5%5FeCatalog&contenturl=https://s7d2.scene7.com/skins/&config2=companypreset&asset=Kennametal/A%2D21%2D06405%5FWIDIA%20Hanita%20Advances%5F2022%5FMetric%5FFINAL%5FLR",
        },
      ],
      bigImg: true,
      src: photos.img3,
    },
    aerospace: {
      title: "Aerospace",
      subtitle:
        "Modern solutions for the aviation industry guaranteeing effective and precise work. Widia tools are durable and resistant to external factors and ensure compliance with all safety standards.",
      links: [
        {
          text: "View catalog",
          url: "https://s7d2.scene7.com/s7viewers/html5/eCatalogSearchViewer.html?emailurl=https://s7d2.scene7.com/s7/emailFriend&searchserverurl=http://s7search1.scene7.com/s7search/&serverUrl=https://s7d2.scene7.com/is/image/&config=Scene7SharedAssets/Universal%5FHTML5%5FeCatalog%5FSearch&contenturl=https://s7d2.scene7.com/skins/&config2=companypreset&asset=Kennametal/A%2D18%2D05897%5FWID%5FAEROSPACE19%5FCatalog",
        },
      ],
      bigImg: true,
      src: photos.img4,
    },
  };
};
