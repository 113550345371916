import {graphql, useStaticQuery} from "gatsby";

export const useWidiaPhotos = () => {
  const query = useStaticQuery(graphql`
      query {
          logo: file(relativePath: { eq: "image/widia/widia-logo.png" }) {
              childImageSharp {
                  fluid(maxHeight: 350, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          hero: file(relativePath: { eq: "image/widia/widia_hero.png" }) {
              childImageSharp {
                  fluid(maxHeight: 1080, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          img1: file(relativePath: { eq: "image/widia/main-catalogue.png" }) {
              childImageSharp {
                  fluid(maxHeight: 600, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          img2: file(relativePath: { eq: "image/widia/turning.png" }) {
              childImageSharp {
                  fluid(maxHeight: 600, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          img3: file(relativePath: { eq: "image/widia/advances.png" }) {
              childImageSharp {
                  fluid(maxHeight: 600, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          img4: file(relativePath: { eq: "image/widia/aerospace.png" }) {
              childImageSharp {
                  fluid(maxHeight: 600, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
      }
  `);
  return query;
};
